import React from "react"
import starIcon from "../../assets/images/technical-support.png"
import MediumAIToolbox from "./MediumAIToolbox"
import { Link } from "gatsby"

const MediumAIToolboxLatest = () => {
  return (
    <section className="blog-area pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <img src={starIcon} alt="blog" />
            AI Toolbox
          </span>
          <h2>Artificial Intelligence tools and frameworks</h2>
          <p>Latest Insights</p>
        </div>
        <MediumAIToolbox limit={3} />
        <div className="text-center">
          <Link to="/ai-toolbox" className="default-btn">
            <i className="flaticon-view"></i>
            More Articles
            <span></span>
          </Link>
        </div>
      </div>
    </section>
  )
}

export default MediumAIToolboxLatest
