import React from "react"
import starIcon from "../../assets/images/technical-support.png"
import ResourceArticles from "./ResourceArticles"
import { Link } from "gatsby"

const ResourceArticlesLatest = () => {
  return (
    <section className="blog-area pt-100 pb-70 bg-fffbf5">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <img src={starIcon} alt="blog" />
            Resources
          </span>
          <h2>Worth Sharing</h2>
        </div>
        <ResourceArticles limit={3} />
        <div className="text-center">
          <Link to="/resources" className="default-btn">
            <i className="flaticon-view"></i>
            More Resources
            <span></span>
          </Link>
        </div>
      </div>
    </section>
  )
}

export default ResourceArticlesLatest
