import React from "react"
import starIcon from "../../assets/images/technical-support.png"
import MediumArticles from "./MediumArticles"
import { Link } from "gatsby"

const MediumArticlesLatest = () => {
  return (
    <section className="blog-area pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <img src={starIcon} alt="blog" />
            Articles
          </span>
          <h2>Let's Talk About Tech</h2>
          <p>Latest Insights</p>
        </div>
        <MediumArticles limit={3} />
        <div className="text-center">
          <Link to="/articles" className="default-btn">
            <i className="flaticon-view"></i>
            More Articles
            <span></span>
          </Link>
        </div>
      </div>
    </section>
  )
}

export default MediumArticlesLatest
