import React from 'react';
import ReactWOW from 'react-wow';

import { StaticImage } from "gatsby-plugin-image"

const MainBanner = () => {
    return (
        <div className="main-banner-area">
            <div className="container">
                <div className="banner-area-content">

                    <ReactWOW delay='.1s' animation='fadeInLeft'>
                        <h1>Delivering cutting-edge technology solutions</h1>
                    </ReactWOW>

                    <ReactWOW delay='.1s' animation='fadeInUp'>
                        <StaticImage
                            src="../../assets/images/main-banner/world1.png"
                            quality={95}
                            width={790}
                            height={780}
                            formats={["auto", "webp", "avif"]}
                            alt="World"
                            objectFit="none"
                            objectPosition={"center"}
                        />
                    </ReactWOW>
                </div>
            </div>
        </div>
    )
}

export default MainBanner;