import React from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import MainBanner from "../components/DataAnalyticsMLConsulting/MainBanner"
import Technologies from "../components/Common/Technologies"
import StartProjectTwo from "../components/Common/StartProjectTwo"
import MediumArticlesLatest from "../components/BlogContent/MediumArticlesLatest"
import MediumAIToolboxLatest from "../components/BlogContent/MediumAIToolboxLatest"
import ResourceArticlesLatest from "../components/BlogContent/ResourceArticlesLatest"

const Home = () => {
  return (
    <Layout>
      <Navbar />
      <MainBanner />
      <MediumArticlesLatest />
      <MediumAIToolboxLatest />
      <ResourceArticlesLatest />
      <Technologies />
      <StartProjectTwo />
      <Footer />
    </Layout>
  )
}

export default Home
